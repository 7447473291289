/*
 * @Descripttion:
 * @version:
 * @Author: linxi
 * @email: 2194421430@qq.com
 * @Date: 2021-11-29 10:33:16
 * @LastEditors: linxi
 * @LastEditTime: 2023-02-13 15:54:14
 */
import { unwrapResult } from '@reduxjs/toolkit'
import {
  Button,
  Card,
  Grid,
  List,
  Popup,
  PullToRefresh,
  Swiper,
  Toast,
} from 'antd-mobile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd-mobile'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { AppDispatch } from '../../app/store'
import styles from './clinicOverview.module.css'
import { useLocation } from 'react-router-dom'
import home1 from '../../components/images/home.png'
import home2 from '../../components/images/home.png'
import rightIconArea from '../../components/images/rightIconArea.png'
import rightIconPhone from '../../components/images/rightIconPhone.png'
import { getHomeDetail, getWechatJsSdkSignature } from '../home/HomeSlice'
import wx from 'weixin-js-sdk' // 引入微信js-sdk
import {selectAccessToken, selectAppId, selectTenantKey} from '../../app/appSlice'
import {
  HomeIcon,
} from '../../components/Icons/Icons'

export const ClinicOverview = () => {
  const dispatch = useDispatch<AppDispatch>()

  const history = useHistory()

  const [homeData, setHomeData] = useState<any>()

  const [visible, setVisible] = useState(false)

  const appId = useSelector(selectAppId)

  const queryParams = new URLSearchParams(window.location.search)
  const state = queryParams.get('state')

  const tenantKey = useSelector(selectTenantKey)

  const accessToken = useSelector(selectAccessToken)

  const pictureList = homeData?.pictureList.map((v: any) => (
    <Swiper.Item key={v.id}>
      <div
        className={styles.swiperContent}
        onClick={() => {
          Toast.show(`你点击了卡片 `)
        }}
      >
        <img src={v.info} alt='' style={{ width: '100%', height: '187px' }} />
      </div>
    </Swiper.Item>
  ))

  const morenPictureList = (
    <Swiper.Item key={home1}>
      <div
        className={styles.swiperContent}
        onClick={() => {
          Toast.show(`你点击了卡片 `)
        }}
      >
        <img src={home1} alt='' style={{ width: '100%', height: '187px' }} />
      </div>
    </Swiper.Item>
  )

  useEffect(() => {

    let key = tenantKey
    if (state) {
      key = state
    }

    dispatch(getHomeDetail({ tenantKey: key }))
      .then(unwrapResult)
      .then((res) => {
        setHomeData(res)
      })
  }, [])

  // useEffect(() => {
  //   wx.ready(function () {
  //     // config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
  //     wx.checkJsApi({
  //       jsApiList: ['openLocation'], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
  //       success: function (res: any) {
  //         alert('支持的sdk列表：' + JSON.stringify(res))
  //       },
  //     })
  //   })
  //   wx.error(function (res: any) {
  //     // alert('验签失败：' + JSON.stringify(res))
  //   })
  // }, [])
  useEffect(() => {

    let key = tenantKey
    if (state) {
      key = state
    }

    if (key) {
      const data = {
        currUrl: window.location.href.split('#')[0],
        appid: appId,
      }
      dispatch(getWechatJsSdkSignature(data))
        .then(unwrapResult)
        .then((res: any) => {
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.noncestr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名
            jsApiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData'], // 必填，需要使用的 JS 接口列表
          })

            const tenantName = localStorage.getItem('tenantName')
            wx.ready(function () {   //需在用户可能点击分享按钮前就先调用

                wx.updateAppMessageShareData({
                    title: tenantName + ' - 机构详情', // 分享标题
                    desc: '', // 分享描述
                    link: window.location.href.split('?')[0] + '?share=true&state='+key, // 分享链接，
                    imgUrl: '1111', // 分享图标
                    success: function () {
                    },
                    fail: function (err:any) {
                        console.error('分享数据更新失败', err);
                    }
                })

                wx.updateTimelineShareData({
                    title: tenantName + ' - 机构详情', // 分享标题
                    link: window.location.href.split('?')[0] + '?share=true&state='+key, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: '1111', // 分享图标
                    success: function () {
                        // 设置成功
                    },
                    fail: function (e:any) {
                        alert("朋友圈分享设置失败：" + JSON.stringify(e))
                    }
                })
            });
        })

    }
  }, [])

  return (
    <div className={styles.clinicOverview}>
      <div className={styles.header}>
        <Swiper autoplay>
          {homeData?.pictureList?.length ? pictureList : morenPictureList}
        </Swiper>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Card className={styles.titleContent}>
          <div className={styles.title}><span style={{paddingRight: '6px'}}>{homeData?.name}</span><HomeIcon onClick={() => {
            history.replace('/')
          }} style={{ height: '22px', fill: '#0EBC6A' }} /></div>
          <div className={styles.titleTagContent}>
            <div className={styles.titleTopContent}>
              {/* 左边标签 */}
              <div className={styles.titleTag}>
                {homeData?.labelList?.map((v: any) => {
                  return <span className={styles.titleTagText}>{v?.info}</span>
                })}
              </div>
            </div>
            <div className={styles.titleBottomContentOne}>
              {/* 左边电话 */}
              <div className={styles.titleBottomLeftTextPhone}>
                联系电话：{homeData?.phone}
              </div>
              {/* 右边icon */}
              <div
                className={styles.titleBottomRightContent}
                onClick={() => {
                  setVisible(true)
                }}
              >
                <div className={styles.titleBottomRightText}>
                  <div
                    style={{
                      width: '25px',
                      height: '25px',
                      background: '#ecf8f0',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '50%',
                    }}
                  >
                    <img
                      src={rightIconPhone}
                      alt=''
                      style={{
                        width: '17px',
                        height: '17px',
                        marginLeft: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.titleBottomContent}>
              {/* 右边地址 */}
              <div className={styles.titleBottomLeftTextMain}>
                <span className={styles.titleBottomLeftTextMainTitle}>
                  诊所地址：
                </span>
                <div className={styles.titleBottomLeftText}>
                  {homeData?.address}
                </div>
              </div>
              {/* 右边icon */}
              <div className={styles.titleBottomRightContent}>
                <div
                  className={styles.titleBottomRightText}
                  onClick={() => {
                    console.log(11111111111)
                    wx.openLocation({
                      latitude: Number(homeData?.latitude), // 纬度，浮点数，范围为90 ~ -90
                      longitude: Number(homeData?.longitude), // 经度，浮点数，范围为180 ~ -180。
                      name: homeData?.name, // 位置名
                      address: homeData?.address, // 地址详情说明
                      scale: 14, // 地图缩放级别,整型值,范围从1~28。默认为最大
                    })
                  }}
                >
                  <div
                    style={{
                      width: '25px',
                      height: '25px',
                      background: '#ecf2fe',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '50%',
                    }}
                  >
                    <img
                      src={rightIconArea}
                      alt=''
                      style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '4px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Card className={styles.clinicOverviewContent}>
        <div className={styles.clinicOverviewContentTitle}>诊所简介</div>
        {homeData?.intro?.split('\n')?.map((v: any) => {
          return (
            <div className={styles.clinicOverviewContentMain} key={v}>
              {v}
            </div>
          )
        })}
      </Card>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        position='bottom'
        bodyStyle={{ height: '29vh', borderRadius: '5px 5px 0 0' }}
      >
        <div className={styles.popPhone}>{homeData?.phone}</div>
        <div
          className={styles.popCall}
          onClick={() => {
            window.location.href = 'tel:' + homeData?.phone
          }}
        >
          呼叫
        </div>
        <div className={styles.popBack}></div>
        <div
          className={styles.popCancel}
          onClick={() => {
            setVisible(false)
          }}
        >
          取消
        </div>
      </Popup>
    </div>
  )
}
